$primary-color: 'red';
$headerHeight: 50px;

@import './_fonts.scss';

body {
	background-color: white;
	color: black;
}

main {
    width: 100%;
}

header {
	.panel {
		height: $headerHeight;
	}
}

.text-error {
	color: red;
}

.bg-secondary {
	background-color: red!important;
}

.opacity-1 {
	opacity: .4;
}

.cursor-pointer {
	&:focus,&:active,&:focus,&:hover {
		color: $primary-color;
	}
	cursor: pointer;
}

// ANIMATION CLASSES
.animation-rotation {
	animation: rotation 2s infinite linear;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

#competition {
    width: 100%;
	$mapHeight: 760px;
	$profilHeight: 200px;

	#competition-stages-list {
		.active {
			background-color: orange!important;
		}
	}

	.trackers-legend {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.map-loading {
		height: $mapHeight;
		height: 500px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.profil-loading {
		height: $profilHeight;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	#map {
		height: $mapHeight;
		width: 100%;
		position: relative;

		.leaflet-tooltip {
			border: 0;
			padding: 0;
		}

		.leaflet-div-icon {
			border: 0;
			background: transparent;
		}
	}

	form[role="search"], .input-group, .form-outline, .form-label {
		width: 100%;
		flex: 1;

		button[type="button"] {
			height: 39px;
		}
	}

	.list-group {
		height: 300px;
		
		@media (min-width: 768px) {
			height: 714px!important;
		}
		overflow: auto;
	}

	.input-group {
		margin-top: 10px;

		@media (min-width: 768px) {
			margin-top: 0!important;
		}
	}

	#col-fixed-size-350 {
		flex: 0 0 100%;

		// Medium devices (tablets, 768px and up)
		@media (min-width: 768px) {
			flex: 0 0 350px;
		}
	}
}

.bg-2A20B6 {
	&.leaflet-tooltip-left:before {
		border-left-color: #2A20B6;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #2A20B6;
	}
}
.bg-FF006E {
	&.leaflet-tooltip-left:before {
		border-left-color: #FF006E;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #FF006E;
	}
}
.bg-FF0000 {
	&.leaflet-tooltip-left:before {
		border-left-color: #FF0000;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #FF0000;
	}
}
.bg-EDE904 {
	&.leaflet-tooltip-left:before {
		border-left-color: #EDE904;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #EDE904;
	}
}
.bg-16701D {
	&.leaflet-tooltip-left:before {
		border-left-color: #16701D;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #16701D;
	}
}
.bg-000000, .bg-black {
	&.leaflet-tooltip-left:before {
		border-left-color: #000000;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #000000;
	}
}

.bg-FF8000 {
	&.leaflet-tooltip-left:before {
		border-left-color: #FF8000;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #FF8000;
	}
}

.bg-488FC4 {
	&.leaflet-tooltip-left:before {
		border-left-color: #488FC4;
	}
	&.leaflet-tooltip-right:before {
		border-right-color: #488FC4;
	}
}
