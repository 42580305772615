
/* Gill Sans MT */
@font-face {
	font-family: 'Gill Sans MT';
	src: local('Gill Sans MT'),
		url('../../public/fonts/gill-sans-mt/gill-sans-mt-bold.woff') format('woff'),
		url('../../public/fonts/gill-sans-mt/gill-sans-mt-bold.ttf') format('ttf');
	font-style: bold;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Gill Sans MT', verdana, arial;
}

/* Century Gothic Bold */
@font-face{
    font-family: 'Century Gothic';
    src: url('../../public/fonts/century-gothic/CenturyGothic.woff')format('woff'),
        url('../../public/fonts/century-gothic/CenturyGothic.woff2')format('woff2'),
        url('../../public/fonts/century-gothic/CenturyGothic.ttf')format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: 'Century Gothic';
    src: url('../../public/fonts/century-gothic/CenturyGothic-Bold.woff')format('woff'),
        url('../../public/fonts/century-gothic/CenturyGothic-Bold.woff2')format('woff2'),
        url('../../public/fonts/century-gothic/CenturyGothic-Bold.ttf')format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
	font-family: 'Century Gothic', times new roman, georgia;
}
